import { loadTranslations } from '@angular/localize';
import { ILogObj, Logger } from 'tslog';
import { cookieValueExtractor } from './cookie.utils';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    $localize: any;
  }
}

export class AngularI18nSupport {
  private readonly fixedLocalizeLogger: Logger<ILogObj>;
  private readonly intervalLogger: Logger<ILogObj>;
  private readonly logger: Logger<ILogObj>;
  private readonly defaultLanguage: string;

  constructor(
    parentLogger: Logger<ILogObj>,
    defaultLanguage: string
  ) {
    this.fixedLocalizeLogger = parentLogger.getSubLogger({ name: 'FixedLocalize' });
    this.intervalLogger = parentLogger.getSubLogger({ name: 'localizeObserver' });
    this.logger = parentLogger.getSubLogger({ name: 'AngularI18nSupport' });
    this.defaultLanguage = defaultLanguage;
  }

  async setup(): Promise<void> {
    const locale = this.resolveLocale();
    this.setLocaleToHTMLElement(locale);

    if (!window.$localize) {
      await import('@angular/localize/init');
      if (locale !== 'en') {
        loadTranslations({});
      }
      this.startInterval();
    }

    this.patchGlobalAngularLocalize(window.$localize);
  }

  private resolveLocale(): string {
    let locale: string | undefined;

    let localStorageLanguageSettings = localStorage.getItem('sdw_language_settings');
    if (localStorageLanguageSettings && localStorageLanguageSettings !== '') {
      this.logger.trace(`found sdw_language_settings, parsing it "${localStorageLanguageSettings}"`);
      try {
        const parsedLanguageSettings = JSON.parse(localStorageLanguageSettings);
        if (parsedLanguageSettings?.language) {
          this.logger.debug(`found language in sdw_language_settings: ${parsedLanguageSettings.language}`);
          locale = parsedLanguageSettings.language;
        }
      } catch (e) {
        this.logger.error('error parsing sdw_language_settings', e);
      }
    }

    if (!locale) {
      this.logger.trace('looking for language cookie');
      const sdwLangCookieValue = cookieValueExtractor(document.cookie, 'sdw_lang');
      if (sdwLangCookieValue && sdwLangCookieValue !== '') {
        this.logger.debug('found sdw_lang cookie', sdwLangCookieValue);
        locale = sdwLangCookieValue;
      }
    }

    if (locale && locale !== '') {
      return locale;
    } else {
      this.logger.debug(`no sdw_lang cookie found, using default ${this.defaultLanguage}`);
      return this.defaultLanguage;
    }
  }

  private patchGlobalAngularLocalize(fixedLocalize: unknown): void {
    this.fixedLocalizeLogger.info('setting up $localize override', fixedLocalize);
    const logger = this.fixedLocalizeLogger;
    Object.defineProperty(window, '$localize', {
      set(v: any) {
        if (v !== fixedLocalize) {
          logger.warn('suppressing $localize override call with', v);
        }
      },
      get() {
        return fixedLocalize;
      }
    });
  }

  private setLocaleToHTMLElement(locale: string): void {
    const htmlElements = document.getElementsByTagName('html');
    if (htmlElements?.length > 0) {
      this.logger.trace(`setting lang to ${locale}`)
      const htmlElement = htmlElements.item(0);
      htmlElement!.lang = locale;
    }
  }

  private startInterval(): void {
    let lastLocalize: unknown = window.$localize;

    this.intervalLogger.info('starting interval with', lastLocalize);
    // we should consider removing this again, but someone could remove the readonly flag,
    // so maybe it still makes sense, but we don't need to check it that often
    setInterval(() => {
      if (window.$localize !== lastLocalize) {
        lastLocalize = window.$localize;
        this.intervalLogger.info('localize changed', lastLocalize);
      }
    }, 15000);
  }
}
