import { MFEDiscoveryData } from '@allianz/appshell/models/mfe-discovery';
import { sdwPortalLogger } from './logger';
import { AppShell } from '@allianz/appshell';
import { AngularI18nSupport } from './angular-i18n-support';

export function main() {
  interface SdwPortalConfig {
    defaultLanguage: string;
    appShellManifest: MFEDiscoveryData;
    fallback?: boolean;
  }

  const configLoaderLogger = sdwPortalLogger.getSubLogger({ name: 'ConfigLoader' });

  const appShell = new AppShell('main_wrapper', undefined, sdwPortalLogger);

  configLoaderLogger.trace('fetching config');

  function createFallbackConfiguration(errorMessage: string): Promise<SdwPortalConfig> {
    const error = new Error(errorMessage);
    sdwPortalLogger.error(error);
    const manifestPromise = import('./minimal-fallback.manifest.json');
    return manifestPromise.then((minimalFallbackManifest) => {
      return {
        defaultLanguage: 'en',
        appShellManifest: minimalFallbackManifest.default,
        fallback: true
      };
    });
  }

  const fetchConfig = fetch('/config/sdw-portal.json', { headers: { accept: 'application/json' } })
    .catch((err) => {
      return { ok: false, statusText: err.statusText ?? 'fetch failed', json: () => Promise.reject(err) };
    }).
    then(response => {
      if (response.ok) {
        configLoaderLogger.trace('receiving config');
        return response.json().catch(err => {
          configLoaderLogger.error('unexpected error while parsing configuration', err);
          return null;
        }).then((config) => {
          if (config) {
            sdwPortalLogger.trace('config received', config);
            return config as SdwPortalConfig;
          } else {
            return createFallbackConfiguration('sdw-portal config loaded without proper content');
          }
        });
      } else {
        return createFallbackConfiguration(`sdw-portal config not loaded: ${response.statusText}`);
      }
    });

  return fetchConfig
    .then(async (config) => {
      configLoaderLogger.trace('config received', config);
      if (config.fallback) {
        sdwPortalLogger.trace(`navigating to error page because of fallback from ${location.href} to /auth/error/500-1000`);
        if (location.pathname !== '/auth/error/500-1000') {
          // prevent infinite loop
          // we can't send a message to the auth
          // as we have no app shell api and also no auth mfe here
          // so using location instead
          location.pathname = '/auth/error/500-1000';
        }
      }

      appShell.configureViews(config.appShellManifest);

      await new AngularI18nSupport(sdwPortalLogger, config.defaultLanguage).setup();

      appShell.init();
    })
    .catch((err) => sdwPortalLogger.error(err))
    .then(() => sdwPortalLogger.info('initialization done'));
}
