function findCookieEntry(documentCookie: string | undefined, cookieName: string | undefined) {
  const entryPrefix = cookieName ? cookieName + '=' : undefined;
  return entryPrefix && documentCookie?.split('; ').find((entry) => entry.startsWith(entryPrefix));
}

function getCookieValue(cookieEntry: string[] | undefined) {
  return (cookieEntry && cookieEntry.length === 2 ? cookieEntry[ 1 ] : undefined);
}

function splitCookieEntry(cookieEntry: string | undefined) {
  return (cookieEntry ? cookieEntry.split('=') : undefined);
}

export function cookieValueExtractor(documentCookies: string | undefined, cookieName: string | undefined) {
  return documentCookies ? getCookieValue(splitCookieEntry(findCookieEntry(documentCookies, cookieName))) : undefined;
}
